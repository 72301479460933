<template>
  <div
    class="SA-content d-flex flex-column justify-content-center align-items-center mx-auto m-5"
  >
    <div class="m-3 content-img flex-shrink-0">
      <img class="img-fluid" name="side-img" :src="entity.imgUrl" />
    </div>
    <div
      class="content-infos d-flex flex-column pl-4 py-3"
    >
      <div>
        <button class="content-return my-2" name="content-return" @click="back">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
      <h2 class="content-infos-title mx-auto mb-2">
        {{ entity.name }}
      </h2>
      <div class="content-infos-description my-4">
        <p v-if="lang === 'ar'" class="text-right">
          {{ entity.description }}
        </p>
        <p v-else>
          {{ entity.description }}
        </p>
      </div>
      <h2 v-if="entity.menus" class="content-menu-title mx-auto mb-2">
        {{ entity.menu_text }}
      </h2>
      <div v-if="entity.menus" class="content-menu d-flex flex-column flex-sm-row flex-wrap align-content-stretch justify-content-center my-4">
        <div
          class="content-menu-list pl-4 pr-3"
          v-for="menu in entity.menus"
          :key="menu.id"
          :menu="menu"
        >
          <h3 class="content-food-title mx-auto mb-2">{{ menu.name }}</h3>
          <div v-if="lang === 'ar'">
            <div
              dir="rtl"
              class="p-1 text-right"
              v-for="food in menu.content"
              :key="food.id"
              :food="food"
            >
              <span class="content-food-price-ar">{{ food.price }} <span class="food-price-currency">{{ currency[lang] }}</span></span>
              <span class="content-food-name mr-3">{{ food.name }}</span>
            </div>
          </div>
          <div v-else>
            <div
              class="p-1"
              v-for="food in menu.content"
              :key="food.id"
              :food="food"
            >
              <span class="content-food-name mr-3">{{ food.name }}</span>
              <span class="content-food-price">{{ food.price }} <span class="food-price-currency">{{ currency[lang] }}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    entity: Object,
  },
  data() {
    return {
      currency: {
        fr: 'DT',
        en: 'TD',
        ar: 'د.ت',
      }
    };
  },
  computed: {
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    }
  },
  methods: {
    back() {
      if (this.$router.currentRoute.value.name === "attraction") {
        this.$router.replace({ name: "attractions" });
      } else if (this.$router.currentRoute.value.name === "service") {
        this.$router.replace({ name: "services" });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style src="./SApage.css" scoped></style>
