<template lang="">
  <div
    class=" my-1 d-flex flex-column flex-sm-row flex-wrap align-content-stretch justify-content-center align-items-center"
  >
    <CircleCard
      v-for="child in childs"
      :key="child.id"
      :child="child"
      srcName="service"
    ></CircleCard>
  </div>
</template>
<script>
import CircleCard from "@/components/CircleCard/CircleCard";
export default {
  components: {
    CircleCard,
  },
  computed: {
    childs() {
      return this.$store.getters['services/getServices'];
    },
  },
};
</script>
<style src="./Services.css" scoped></style>
