<template>
  <div class="d-flex align-items-center">
    <transition name="fade" mode="out-in">
      <SApage :key="$route.params.id" :entity="service"> </SApage>
    </transition>
  </div>
</template>

<script>
import SApage from "@/components/SApage/SApage.vue";
export default {
  components: {
    SApage,
  },
  computed: {
    service() {
      return this.$store.getters['services/getServices'][this.$route.params.id];
    },
  },
};
</script>

<style src="./ServicePage.css"></style>
